<template>
    <div class="class-details">
        <ek-table
            :columns="columns"
            :items="classSubList"
            @details="goToDetails"
            no_delete
            no_select
        >
            <template #table-header class="p-2">
                <div class="d-flex p-1"></div>
                <div class="d-flex justify-content-end align-items-end">
                    <span class="p-1"
                        >ترتيب الصف:
                        <b-badge variant="success">
                            {{ classDetailsDto.order }}</b-badge
                        >
                    </span>
                </div>
            </template>
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
    data: () => ({}),
    props: ["id"],
    computed: {
        ...mapState({
            classDetailsDto: state => state.classes.classDetailsDto,
            classList: state => state.classes.classList,
            columns: state => state.classes.columns
        }),
        ...mapGetters(["classSubList"])
    },
    methods: {
        goToDetails({ row }) {
            this.$router.push(`/admin/subjects/${row.id}`);
        },
        ...mapActions(["getByIdClass"])
    },
    created() {
        this.getByIdClass(this.id);
    }
};
</script>

<style lang="scss"></style>
